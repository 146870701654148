import axios from "axios";
const api = axios.create(
    // {
    //     baseURL: "http://10.141.59.111:8080",
    //     timeout: 1000
    // }

    {
        baseURL: "http://123.60.186.210:8080",
        timeout: 1000
    }

);
export default api;
