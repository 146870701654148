import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Main from '../views/SoftwareMain.vue'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'login',
        component: Login
    },
    {
        path: '/',
        name: 'main',
        component: Main
    }
]

const router = new VueRouter({
    routes
})

export default router
