import Vue from 'vue'
import App from './App.vue'
import axios from "./api/request.js";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from "@/router";
import "./global.css";
Vue.prototype.$axios = axios
axios.defaults.baseURL = '/api'
Vue.use(ElementUI);
Vue.config.productionTip = false
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
