<template>
  <div class="file1">
    <div class="el-main">
    <el-upload
        class="file"
        action="http://123.60.186.210:8080/upload" >
      <el-button size="small" type="primary">点击上传</el-button>
    </el-upload>
    <template>
      <el-table
          :data="tableData"
          style="width: 100%">
        <el-table-column
            prop="filename"
            label="文件名"
            width="180">
        </el-table-column>
        <el-table-column
            prop="filepath"
            label="文件地址">
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="100">
          <template slot-scope="scope">
            <el-button class="login_button" type="primary" @click="download(scope.row)">下载</el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>
  </div>
    <footer class = "foot">
      <div >
        <a class="text-conline--link" href="//beian.miit.gov.cn/" target="_blank">互联网ICP备案：浙ICP备2024105962号-1</a>
      </div>
    </footer>
  </div>

</template>

<script>
import api from "@/api/request";
export default {
  data() {
    return {
      tableData: [{
        filename:"",
        filepath:""
        }]
      }
    },
  mounted() {
    this.getUser()
  },
  methods: {
    getUser () {
      //axios从后台获取数据
      api({url: "/filelist",method:"get"})
          .then((res=>{
            console.log(res)
            if(res.status == '200'){
              console.log(res)
              this.tableData = res.data;
              console.log(this.tableData)
              // window.location.reload()
            }
          }))
    },
    download(row){
      // location.href = 'http://10.141.59.111:8080/download?filename='+ row.filename
      location.href = 'http://123.60.186.210:8080/download?filename='+ row.filename
    }
  }
}
</script>

<style>
.file1{
  width: 100vw;
  height: 100vh;
  background-color: rgb(233,250,255);
  box-sizing: border-box;
}
.el-main{
  background-image: url(../images/background.jpg);
  background-position: 50% 50%;
  background-size: cover;
  width: 100%;
  height: 96%;
}
.file{
	font-size: 18px;
	color:#808080;
	text-align: center;
	cursor: pointer;
}
.foot {
  position: flex;
  bottom: 40px;
  padding: 0%;
  width: 100%;
  height: 0;
  text-align: center;
}
</style>
